import { yupResolver } from '@hookform/resolvers/yup';
import { difference } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCompanyStore } from '@/store/company';
import { RadioButtonFilled, RadioButtonOutline } from '@/svg';
import { CompanyRegistrationSteps } from '@/util/constants';

import CompanyTextInputField from './CompanyTextInputField';
import CustomButton from './CustomButton';
import DatePickerField from './DatePickerField';
import DateSelectField from './DateSelectField';
import FilesUploadField, { FileItem } from './FilesUploadField';
import IdCardIssueLocationField from './IdCardIssueLocationField';
import SelectField from './SelectField';

interface IProps {
  nextStep: (val: number) => void;
}

const maxFileSize = 2; // Maximum 2 MB per file

enum RepresentativeCountry {
  Local = '本國籍',
  Foreigner = '外國籍',
  JuridicalPerson = '法人'
}

enum CorporationCountry {
  Local = '本國籍',
  Foreigner = '外國籍'
}

const schema = yup
  .object({
    representative_country: yup.string().required('representative country is required'),
    representative_name: yup.string().required('representative name is required'),
    representative_registration_number: yup
      .string()
      .required()
      .test('format-validation', '格式錯誤', function (value) {
        const representative_country = this.parent.representative_country;
        if (representative_country === RepresentativeCountry.Local) {
          return /^[A-Z][1-2]\d{8}$/.test(value);
        } else if (representative_country === RepresentativeCountry.Foreigner) {
          // TODO: 居留證檢查
          return true;
        } else if (representative_country === RepresentativeCountry.JuridicalPerson) {
          // TODO: 統一編號檢查
          return true;
        }
        return false;
      }),
    issue_date: yup
      .object({
        year: yup.string().required(),
        month: yup.string().required(),
        date: yup.string().required()
      })
      .required(),
    issue_location: yup
      .object({
        city: yup.string(),
        type: yup.string()
      })
      .default(null)
      .when(
        ['representative_country', 'corporation_country'],
        ([representative_country, corporation_country], schema) => {
          if (representative_country === RepresentativeCountry.Local) {
            return schema.required();
          } else if (
            representative_country === RepresentativeCountry.JuridicalPerson &&
            corporation_country === CorporationCountry.Local
          ) {
            return schema.required();
          }
          return schema.notRequired();
        }
      ),
    birthday: yup
      .string()
      .when(
        ['representative_country', 'corporation_country'],
        ([representative_country, corporation_country], schema) => {
          if (representative_country === RepresentativeCountry.Local) {
            return schema.required('請輸入正確日期');
          } else if (
            representative_country === RepresentativeCountry.JuridicalPerson &&
            corporation_country === CorporationCountry.Local
          ) {
            return schema.required('請輸入正確日期');
          }
          return schema.notRequired();
        }
      ),
    duration_stay: yup
      .object({
        year: yup.string().required(),
        month: yup.string().required(),
        date: yup.string().required()
      })
      .default(null)
      .when(
        ['representative_country', 'corporation_country'],
        ([representative_country, corporation_country], schema) => {
          if (representative_country === RepresentativeCountry.Foreigner) {
            return schema.required();
          } else if (
            representative_country === RepresentativeCountry.JuridicalPerson &&
            corporation_country === CorporationCountry.Foreigner
          ) {
            return schema.required();
          }
          return schema.notRequired();
        }
      ),
    serial_number: yup
      .string()
      .default(null)
      .when(
        ['representative_country', 'corporation_country'],
        ([representative_country, corporation_country], schema) => {
          if (representative_country === RepresentativeCountry.Foreigner) {
            return schema.required('請輸入正確序號');
          } else if (
            representative_country === RepresentativeCountry.JuridicalPerson &&
            corporation_country === CorporationCountry.Foreigner
          ) {
            return schema.required('請輸入正確序號');
          }
          return schema.notRequired();
        }
      ),

    corporation_country: yup.string().when(['representative_country'], ([representative_country], schema) => {
      if (representative_country === RepresentativeCountry.JuridicalPerson) {
        return schema.required('請輸入國籍');
      }
      return schema.notRequired();
    }),
    corporation_name: yup.string().when(['representative_country'], ([representative_country], schema) => {
      if (representative_country === RepresentativeCountry.JuridicalPerson) {
        return schema.required('請輸入法人姓名');
      }
      return schema.notRequired();
    }),
    corporation_registration_number: yup
      .string()
      .when(['representative_country'], ([representative_country], schema) => {
        if (representative_country === RepresentativeCountry.JuridicalPerson) {
          return schema
            .required('請輸入法人身分證字號')
            .length(10, '身分證字號必須是10個字元')
            .matches(/^[A-Z][1-2]\d{8}$/, '無效的身分證字號');
        }
        return schema.notRequired();
      }),
    id_card: yup
      .mixed<FileItem[]>()
      .default([])
      .test('fileSize', `檔案大小超過${maxFileSize}MB`, (items) => {
        if (items.length > 0) {
          for (const item of items) {
            if (item instanceof File && item.size > maxFileSize * 1024 * 1024) {
              return false;
            }
          }
        }
        return true;
      })
      .test('fileName', '重複上傳同一份檔案', (items) => {
        if (items.length > 0) {
          const fileNameList = items
            .map((item) => (item instanceof File ? item.name : ''))
            .filter((name) => name !== '');
          // ref: https://stackoverflow.com/questions/19655975/check-if-an-array-contains-duplicate-values
          return fileNameList.length === new Set(fileNameList).size;
        }
        return true;
      })
      .test('filesLength', '請上傳身分證正反面圖檔', (items) => items.length >= 2)
  })
  .required();

type RepresentativeFormTypes = yup.InferType<typeof schema>;

const RepresentativeInfoForm = ({ nextStep }: IProps) => {
  const formMethods = useForm<RepresentativeFormTypes>({
    resolver: yupResolver(schema),
    defaultValues: {
      representative_country: RepresentativeCountry.Local
    }
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = formMethods;

  console.log(errors);

  const representativeCountry = formMethods.watch('representative_country');
  const corporationCountry = formMethods.watch('corporation_country') || CorporationCountry.Local;

  const company = useCompanyStore((state) => state.company);
  const updateCompany = useCompanyStore((state) => state.updateCompany);
  const getCompany = useCompanyStore((state) => state.getCompany);

  useEffect(() => {
    (async () => {
      const companyData = await getCompany();
      if (!companyData) return;

      if (companyData.representative_country) {
        setValue('representative_country', companyData.representative_country);
      }
      if (
        companyData.representative_country === RepresentativeCountry.JuridicalPerson &&
        companyData.corporation_country
      ) {
        setValue('corporation_country', companyData.corporation_country);
      }

      for (const [keyName] of Object.entries(getValues())) {
        const key = keyName as keyof RepresentativeFormTypes;
        const value = companyData[key];
        if (value) {
          // Put data from api into react hook form
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setValue(key, value as any);
        }
      }
    })();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(data)) {
        const keyName = key as keyof typeof data;

        if (!value) continue;

        if (typeof data[keyName] === 'object') {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value.toString());
        }
      }

      console.log(company, data);
      formData.delete('id_card');
      if (difference(data.id_card, company.id_card || []).length > 0) {
        data.id_card.forEach((doc) => formData.append('id_card', doc));
      }

      const isSuccess = await updateCompany(formData);
      if (isSuccess) nextStep(CompanyRegistrationSteps.FINANCIAL_INFO_FORM);
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="px-10 xl:px-0 xl:max-w-[1260px] 2xl:max-w-[1550px] m-auto mt-[132px]">
          <div className="flex justify-between">
            <div>
              <div className="flex gap-2.7 mb-5.5">
                <p className="text-base text-black min-w-[272px] text-right">代表人國籍 :</p>
                <div>
                  <div className="flex gap-2.7 my-1">
                    {Object.values(RepresentativeCountry).map((personType) => {
                      return (
                        <div key={personType} className="flex gap-0.5 items-center">
                          {personType === representativeCountry ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonOutline
                              onClick={() => {
                                formMethods.reset(
                                  {
                                    representative_country: personType
                                  },
                                  { keepValues: false, keepErrors: false, keepIsValid: false }
                                );
                              }}
                            />
                          )}
                          <label className="text-xs font-bold">{personType}</label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {representativeCountry === RepresentativeCountry.Local && (
                <div className="flex flex-col gap-5">
                  <CompanyTextInputField
                    name="representative_name"
                    label="代表人姓名"
                    errors={errors}
                    errorMessage="請輸入正確姓名"
                  />
                  <CompanyTextInputField
                    label="代表人身分證字號"
                    name="representative_registration_number"
                    placeholder="請輸入代表人身分證字號"
                    errors={errors}
                    errorMessage="請輸入正確身分證字號"
                  />
                  <div className="flex flex-col gap-[22px]">
                    <div className="flex gap-3 items-center">
                      <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                        身分證發證日期 :
                      </label>

                      <div className="flex gap-1.5 items-center">
                        <DateSelectField
                          yearFieldName="issue_date.year"
                          monthFieldName="issue_date.month"
                          dateFieldName="issue_date.date"
                        />
                      </div>
                    </div>
                    <div className="flex gap-3 items-center">
                      <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                        身分證發證地點 :
                      </label>
                      <div className="flex gap-1.5 items-center">
                        <IdCardIssueLocationField
                          cityFieldName="issue_location.city"
                          issueTypeFieldName="issue_location.type"
                        />
                      </div>
                    </div>

                    <div className="flex gap-2.7 items-start">
                      <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[272px]">
                        出生年月日 :
                      </label>
                      <div>
                        <DatePickerField name="birthday" />

                        {errors.birthday?.message && (
                          <p className="text-xs mt-1 ml-2 text-bright-red">{errors.birthday.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {representativeCountry === RepresentativeCountry.Foreigner && (
                <div className="flex flex-col gap-5">
                  <CompanyTextInputField
                    name="representative_name"
                    label="代表人姓名"
                    errors={errors}
                    errorMessage="請輸入正確姓名"
                  />
                  <CompanyTextInputField
                    label="代表人中華民國居留證統一證號"
                    name="representative_registration_number"
                    placeholder=""
                    errors={errors}
                    errorMessage="請輸入正確中華民國居留證統一證號"
                  />
                  <div className="flex gap-3 items-center">
                    <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                      核發日期 :
                    </label>

                    <div className="flex gap-1.5 items-center">
                      <DateSelectField
                        yearFieldName="issue_date.year"
                        monthFieldName="issue_date.month"
                        dateFieldName="issue_date.date"
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 items-center mt-2">
                    <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                      居留期限 :
                    </label>

                    <div className="flex gap-1.5 items-center">
                      <DateSelectField
                        yearFieldName="duration_stay.year"
                        monthFieldName="duration_stay.month"
                        dateFieldName="duration_stay.date"
                      />
                    </div>
                  </div>

                  <CompanyTextInputField
                    name="serial_number"
                    label="背面序號"
                    errors={errors}
                    errorMessage="請輸入正確序號"
                    className="!w-[187px]"
                  />
                </div>
              )}

              {representativeCountry === RepresentativeCountry.JuridicalPerson && (
                <div className="flex flex-col gap-5">
                  <CompanyTextInputField
                    label="企業名稱"
                    name="representative_name"
                    errors={errors}
                    errorMessage="請輸入正確企業名稱"
                  />
                  <CompanyTextInputField
                    label="統一編號"
                    name="representative_registration_number"
                    errors={errors}
                    errorMessage="請輸入正確統一編號"
                  />
                  <div className="flex gap-3 items-center justify-end">
                    <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                      法人代表人國籍 :
                    </label>
                    <SelectField
                      label={CorporationCountry.Local}
                      name="corporation_country"
                      options={[
                        { name: CorporationCountry.Local, value: CorporationCountry.Local },
                        { name: CorporationCountry.Foreigner, value: CorporationCountry.Foreigner }
                      ]}
                      width="368px"
                    />
                  </div>
                  {corporationCountry === CorporationCountry.Local && (
                    <div className="flex flex-col gap-5">
                      <CompanyTextInputField
                        label="法人代表人姓名"
                        name="corporation_name"
                        errors={errors}
                        errorMessage="請輸入正確法人代表人姓名"
                      />
                      <CompanyTextInputField
                        label="法人代表人身分證字號"
                        name="corporation_registration_number"
                        errors={errors}
                        errorMessage="請輸入正確法人代表人身分證字號"
                      />
                      <div className="flex gap-3 items-center">
                        <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                          身分證發證日期 :
                        </label>
                        <div className="flex gap-1.5 items-center">
                          <DateSelectField
                            yearFieldName="issue_date.year"
                            monthFieldName="issue_date.month"
                            dateFieldName="issue_date.date"
                          />
                        </div>
                      </div>
                      <div className="flex gap-3 items-center">
                        <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                          身分證發證地點 :
                        </label>
                        <div className="flex gap-1.5 items-center">
                          <IdCardIssueLocationField
                            cityFieldName="issue_location.city"
                            issueTypeFieldName="issue_location.type"
                          />
                        </div>
                      </div>
                      <div className="flex gap-2.7 items-start">
                        <label className="text-black text-base not-italic font-normal leading-normal text-right min-w-[272px]">
                          出生年月日 :
                        </label>
                        <div>
                          <DatePickerField name="birthday" />
                          {errors.birthday?.message && (
                            <p className="text-xs mt-1 ml-2 text-bright-red">{errors.birthday.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {corporationCountry === CorporationCountry.Foreigner && (
                    // foreign nationality
                    <div className="flex flex-col gap-5">
                      <CompanyTextInputField
                        label="法人代表人姓名"
                        name="corporation_name"
                        errors={errors}
                        errorMessage="請輸入正確法人代表人姓名"
                      />
                      <CompanyTextInputField
                        label="法人代表人中華民國居留證統一證號"
                        name="corporation_registration_number"
                        errors={errors}
                        errorMessage="請輸入正確法人代表人中華民國居留證統一證號"
                      />
                      <div className="flex gap-3 items-center">
                        <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                          核發日期 :
                        </label>
                        <div className="flex gap-1.5 items-center">
                          <DateSelectField
                            yearFieldName="issue_date.year"
                            monthFieldName="issue_date.month"
                            dateFieldName="issue_date.date"
                          />
                        </div>
                      </div>
                      {/* Period of stay */}
                      <div className="flex gap-3 items-center">
                        <label className="text-black text-base font-normal leading-normal text-right min-w-[272px]">
                          居留期限 :
                        </label>
                        <div className="flex gap-1.5 items-center">
                          <DateSelectField
                            yearFieldName="duration_stay.year"
                            monthFieldName="duration_stay.month"
                            dateFieldName="duration_stay.date"
                          />
                        </div>
                      </div>
                      <CompanyTextInputField label="背面序號" name="serial_number" className="!w-[187px]" />
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex gap-2.7 2.5xl:mr-28">
              {representativeCountry === RepresentativeCountry.Local && (
                <>
                  <p className="text-black text-base min-w-[272px] text-right">身分證文件：</p>
                  <FilesUploadField
                    name="id_card"
                    title="身分證影像檔上傳，限小於<span>2MB</span>的JPG、PNG檔案。"
                    guideDownload={{
                      fileLinkPath: '/pdf/idcard.pdf',
                      text: '「了解身分證文件上傳規範」'
                    }}
                    fileSizeLimit={2}
                  />
                </>
              )}
              {representativeCountry === RepresentativeCountry.Foreigner && (
                <>
                  <p className="text-black text-base min-w-[272px] text-right">居留證文件：</p>
                  <FilesUploadField
                    name="id_card"
                    title="居留證文件檔上傳，限小於<span>2MB</span>的JPG、PNG檔案。"
                    guideDownload={{
                      fileLinkPath: '/pdf/idcard.pdf',
                      text: '「了解居留證文件上傳規範」'
                    }}
                    fileSizeLimit={2}
                  />
                </>
              )}
              {representativeCountry === RepresentativeCountry.JuridicalPerson && (
                <>
                  <p className="text-black text-base min-w-[272px] text-right">
                    {corporationCountry === CorporationCountry.Local ? '法人身分證文件：' : '法人居留證文件：'}
                  </p>
                  <FilesUploadField
                    name="id_card"
                    title={
                      corporationCountry === CorporationCountry.Local
                        ? '身分證影像檔上傳，限小於<span>2MB</span>的JPG、PNG檔案。'
                        : '居留證文件檔上傳，限小於<span>2MB</span>的JPG、PNG檔案。'
                    }
                    guideDownload={{
                      fileLinkPath:
                        corporationCountry === CorporationCountry.Local
                          ? '/pdf/idcard.pdf'
                          : '/pdf/foreignerIdCard.pdf',
                      text:
                        corporationCountry === CorporationCountry.Local
                          ? '「了解身分證文件上傳規範」'
                          : '「了解居留證文件上傳規範」'
                    }}
                    fileSizeLimit={2}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="absolute bottom-7 right-5 flex gap-2">
          <CustomButton
            className="px-4.5 py-0.7 font-bold  rounded-md border border-navy-blue"
            variant="secondary"
            type="button"
            onClick={() => {
              nextStep(CompanyRegistrationSteps.COMPANY_INFO_FORM);
            }}
          >
            修改 | 上一步
          </CustomButton>
          <CustomButton className="text-white bg-navy-blue px-4.5 py-0.7 font-bold rounded-md " type="submit">
            儲存 | 下一步
          </CustomButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default RepresentativeInfoForm;
