import { yupResolver } from '@hookform/resolvers/yup';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import useOutsideClick from '@/hooks/useOutsideClick';
import AccountPasswordChangeModal from '@/pages/v2/AccountPasswordChangeModal';
import { useAccountSteps } from '@/store/accountSteps';
import { useEmployeeStore } from '@/store/employee';
import { ModalType, useModalStore } from '@/store/modal';
import { AccountStepsEnum } from '@/type';

import CustomButton from '../CustomButton';
import CustomInput from './CustomInput';

const schema = yup
  .object()
  .shape({
    username: yup.string().required(),
    last_name: yup.string().required('姓名為必填項'),
    position: yup.string(),
    email: yup.string().email('Enter valid address').required('電子郵件為必填項'),
    tel: yup
      .string()
      .required('例如：0x-000111 或 09xx-000111')
      .matches(/^09\d{8}$/, '例如：0x-000111 或 09xx-000111'),
    tel_extension: yup.string().required(),
    group_name: yup.string().required('需要操作權限')
  })
  .required();

type UserProfileUpdateFormTypes = yup.InferType<typeof schema>;

const MemberProfileUpdate = () => {
  const { id } = useParams();
  const [infoUpdateAble, setInfoUpdateAble] = useState(false);
  const roleList = useEmployeeStore((store) => store.roleList);
  const getRoleList = useEmployeeStore((store) => store.getRoleList);
  const getSelectedEmployee = useEmployeeStore((state) => state.getSelectedEmployee);
  const deleteEmployeeAccount = useEmployeeStore((state) => state.deleteEmployeeAccount);
  const adminRestPassword = useEmployeeStore((state) => state.adminRestPassword);
  const open = useModalStore((state) => state.open);
  const selectedEmployee = useEmployeeStore((state) => state.selectedEmployee);
  const updateEmployeeDetails = useEmployeeStore((state) => state.updateEmployeeDetails);
  const [file, setFile] = useState<File | null>(null);
  const [fileURL, setFileURL] = useState('');
  const [passwordModal, setPasswordModal] = useState<boolean>(false);
  const navigate = useNavigate();
  useAccountSteps((state) => state.step);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<UserProfileUpdateFormTypes>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: '',
      last_name: '',
      position: '',
      email: '',
      tel: '',
      tel_extension: '',
      group_name: ''
    }
  });

  console.log('errors', errors);

  useEffect(() => {
    if (!id) return;
    getSelectedEmployee(Number(id)).then((employee) => {
      if (!employee) return;

      for (const key of Object.keys(getValues())) {
        const keyName = key as keyof UserProfileUpdateFormTypes;
        const value = employee[keyName];
        if (value) {
          setValue(keyName, value);
        }
      }

      setFileURL(employee.photo || '');
    });
    useAccountSteps.setState({ step: AccountStepsEnum.ENTERPRISE_ACCOUNT });
    getRoleList();
  }, []);

  const infoUpdateAbleHandler = (val: boolean) => {
    setInfoUpdateAble(val);
  };

  const deleteAccount = () => {
    if (!id) return;
    deleteEmployeeAccount &&
      deleteEmployeeAccount(() => {
        navigate('/v2/enterprise-account');
      }, Number(id));
  };

  const freezeAccount = () => {
    if (!id) return;
    const formData = new FormData();
    formData.append('status', '2');
    updateEmployeeDetails(Number(id), formData);
  };

  const unFreezeAccount = () => {
    if (!id) return;
    const formData = new FormData();
    formData.append('status', '1');
    updateEmployeeDetails(Number(id), formData);
  };

  const onFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setFileURL(URL.createObjectURL(event.target.files[0]));
    }
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    console.log('data', data);

    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });

    if (file) formData.append('photo', file);

    updateEmployeeDetails(Number(id), formData);
  });

  const buttonControl = useMemo(() => {
    // 0尚未驗證 1驗證 2凍結
    if (infoUpdateAble) {
      return {
        canModify: false,
        canChangePassword: false,
        canFreezeAccount: false,
        canUnfreezeAccount: false,
        canDeleteAccount: false
      };
    }
    switch (selectedEmployee?.status) {
      case 0: {
        return {
          canModify: false,
          canChangePassword: false,
          canFreezeAccount: true,
          canUnfreezeAccount: false,
          canDeleteAccount: true
        };
      }
      case 1: {
        return {
          canModify: true,
          canChangePassword: true,
          canFreezeAccount: true,
          canUnfreezeAccount: false,
          canDeleteAccount: true
        };
      }
      case 2: {
        return {
          canModify: false,
          canChangePassword: false,
          canFreezeAccount: false,
          canUnfreezeAccount: true,
          canDeleteAccount: true
        };
      }
    }
    return null;
  }, [selectedEmployee, infoUpdateAble]);

  const isAccountFreeze = selectedEmployee?.status === 2;

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="rounded-[10px] bg-trans-white min-[1400px]:py-6.2 py-3.5 min-[1400px]:px-8 px-6 mt-[146px] flex flex-col bg-blend-lighten min-[1400px]:gap-4.5 gap-3 ml-10 w-[92%]">
          <div
            className={classNames('flex items-center 2.5xl:gap-8 min-h-[465px]', {
              'min-[1600px]:gap-x-16.7 min-[1500px]:gap-x-13.7 min-[1300px]:gap-x-12 gap-x-10': !infoUpdateAble
            })}
          >
            <div className="relative self-center">
              <IconButton component="label" className="relative z-50">
                <label
                  className="absolute min-[1600px]:text-sm min-[1500px]:text-xs text-xms z-40 text-white cursor-pointer"
                  htmlFor="image"
                >
                  更換照片
                </label>
                <Avatar
                  src={fileURL}
                  className="min-[1600px]:!w-[100px] min-[1600px]:!h-[100px] min-[1500px]:!w-[85px] min-[1500px]:!h-[85px] min-[1200px]:!w-[75px] min-[1200px]:!h-[75px] !w-[60px] !h-[60px]"
                />
                {infoUpdateAble && <input type="file" hidden onChange={onFileChange} id="image" />}
              </IconButton>
            </div>
            {!infoUpdateAble && (
              <div className="font-istok-web flex min-[1600px]:gap-x-16.7 min-[1500px]:gap-x-13.7 min-[1300px]:gap-x-12 gap-x-10 flex-[3.5]">
                <div className="flex flex-col min-[1500px]:gap-y-8.7 min-[1300px]:gap-y-7 gap-y-5">
                  <CustomInfo heading="帳號" data={selectedEmployee?.username || '-'} />
                  <CustomInfo heading="姓名" data={selectedEmployee?.last_name || '-'} />
                  <CustomInfo heading="職稱" data={selectedEmployee?.position || '-'} />
                  <CustomInfo heading="Email" data={selectedEmployee?.email || '-'} />
                </div>
                <div className="flex flex-col min-[1500px]:gap-y-8.7 min-[1300px]:gap-y-7 gap-y-5">
                  <CustomInfo
                    heading="電話"
                    data={`${selectedEmployee?.tel || '-'} #${selectedEmployee?.tel_extension || '-'}`}
                  />
                  <CustomInfo heading="操作權限" data={selectedEmployee?.group_name || ''} />
                  <CustomInfo
                    heading="帳戶狀態"
                    data={selectedEmployee?.status_cht || ''}
                    className={selectedEmployee?.status_cht == '暫停使用' ? '!text-bright-red' : '!text-light-green'}
                  />
                </div>
              </div>
            )}
            {infoUpdateAble && (
              <div className="flex 2.5xl:gap-15">
                <div className="font-istok-web flex flex-col gap-[18px] w-min">
                  <CustomInput<UserProfileUpdateFormTypes>
                    errors={errors}
                    label="帳號"
                    id="username"
                    type="text"
                    register={register}
                    className="text-dark-grey min-[1400px]:!px-4 min-[1200px]:px-3 px-2.5"
                  />
                  <CustomInput<UserProfileUpdateFormTypes>
                    errors={errors}
                    label="姓名"
                    id="last_name"
                    type="text"
                    register={register}
                    className="text-dark-grey min-[1400px]:!px-4 min-[1200px]:px-3 px-2.5"
                  />
                  <CustomInput<UserProfileUpdateFormTypes>
                    errors={errors}
                    label="職稱"
                    id="position"
                    type="text"
                    register={register}
                    className="text-dark-grey min-[1400px]:!px-4 min-[1200px]:px-3 px-2.5"
                  />
                  <CustomInput<UserProfileUpdateFormTypes>
                    errors={errors}
                    label="Email"
                    id="email"
                    type="text"
                    register={register}
                    className="text-dark-grey min-[1400px]:!px-4 min-[1200px]:px-3 px-2.5"
                  />
                </div>
                <div className="flex gap-[18px] flex-col min-[1600px]:max-w-[415px] min-[1500px]:max-w-[375px] min-[1300px]:max-w-[325px] max-w-[265px]">
                  <CustomInput<UserProfileUpdateFormTypes>
                    errors={errors}
                    label="電話"
                    id="tel"
                    type="text"
                    register={register}
                    className="text-dark-grey min-[1400px]:!px-4 min-[1200px]:px-3 px-2.5"
                  />
                  <CustomInput<UserProfileUpdateFormTypes>
                    errors={errors}
                    label="分機"
                    id="tel_extension"
                    type="text"
                    register={register}
                    className="text-dark-grey min-[1400px]:!px-4 min-[1200px]:px-3 px-2.5 !w-[138px]"
                  />
                  <div className="flex  min-[1700px]:gap-7.5 min-[1500px]:gap-6 min-[1300px]:gap-5.5 gap-5 self-end">
                    <p className="min-[1600px]:text-xl min-[1500px]:text-lg text-navy-blue font-bold min-[1600px]:mt-2.5 min-[1500px]:mt-2 min-[1300px]:mt-1.5 min-[1200px]:mt-1 mt-0.5 whitespace-nowrap">
                      操作權限
                    </p>
                    <CustomSelect
                      setValue={setValue}
                      selectedValue={getValues('group_name')}
                      options={roleList.map((role) => role.name)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-between w-full">
            <div className="flex gap-7.2">
              {buttonControl && (
                <>
                  <CustomButton
                    children="資料修改"
                    onClick={() => infoUpdateAbleHandler(true)}
                    type="button"
                    variant={buttonControl.canModify ? 'outline-primary' : 'outline-disabled'}
                    className="font-inter"
                    isDisabled={!buttonControl.canModify}
                  />
                  <CustomButton
                    children="變更密碼"
                    type="button"
                    onClick={() => setPasswordModal(true)}
                    variant={buttonControl.canChangePassword ? 'outline-primary' : 'outline-disabled'}
                    className="font-inter"
                    isDisabled={!buttonControl.canChangePassword}
                  />
                  {isAccountFreeze ? (
                    <CustomButton
                      children="重啟帳號"
                      type="button"
                      variant={buttonControl.canUnfreezeAccount ? 'outline-primary' : 'outline-disabled'}
                      className="font-inter"
                      onClick={() => {
                        open(ModalType.FreezeEmployeeAccount, {
                          buttons: [
                            { text: '取消送出' },
                            {
                              text: '確認重啟帳號',
                              onClick: () => {
                                unFreezeAccount();
                              }
                            }
                          ]
                        });
                      }}
                      isDisabled={!buttonControl.canUnfreezeAccount}
                    />
                  ) : (
                    <CustomButton
                      children="凍結帳號"
                      type="button"
                      variant={buttonControl.canFreezeAccount ? 'outline-primary' : 'outline-disabled'}
                      className="font-inter"
                      onClick={() => {
                        open(ModalType.FreezeEmployeeAccount, {
                          buttons: [
                            { text: '取消送出' },
                            {
                              text: '確認凍結帳號',
                              onClick: () => {
                                freezeAccount();
                              }
                            }
                          ]
                        });
                      }}
                      isDisabled={!buttonControl.canFreezeAccount}
                    />
                  )}

                  <CustomButton
                    children="刪除帳號"
                    type="button"
                    variant={buttonControl.canDeleteAccount ? 'outline-primary' : 'outline-disabled'}
                    className="font-inter"
                    onClick={() =>
                      open(ModalType.DeleteEmployeeAccount, {
                        buttons: [
                          { text: '取消送出' },
                          {
                            text: '確認刪除帳號',
                            onClick: () => {
                              deleteAccount();
                            }
                          }
                        ]
                      })
                    }
                    isDisabled={!buttonControl.canDeleteAccount}
                  />
                </>
              )}
              {infoUpdateAble && (
                <div className="flex">
                  <div className="flex gap-7.2 items-center">
                    <CustomButton
                      children="取消"
                      className="border !border-navy-blue min-[1600px]:text-lg min-[1500px]:text-base min-[1300px]:text-sm text-xms font-bold rounded-mdlg min-[1600px]:w-[122px] min-[1500px]:w-[100px] min-[1300px]:w-[85px] w-[75px] min-[1600px]:h-9 min-[1500px]:h-7.5 min-[1300px]:h-6.5 h-5.5"
                      variant="secondary"
                      onClick={() => infoUpdateAbleHandler(false)}
                      type="button"
                    />
                    <CustomButton
                      children="儲存"
                      className="min-[1600px]:text-lg min-[1500px]:text-base min-[1300px]:text-sm text-xms font-bold rounded-mdlg min-[1600px]:w-[122px] min-[1500px]:w-[100px] min-[1300px]:w-[85px] w-[75px] min-[1600px]:h-9 min-[1500px]:h-7.5 min-[1300px]:h-6.5 h-5.5"
                      variant="primary"
                      type="submit"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <AccountPasswordChangeModal
        open={passwordModal}
        setOpen={() => setPasswordModal(false)}
        isAdmin={true}
        onSubmit={async (newPassword) => {
          if (selectedEmployee) {
            return await adminRestPassword(selectedEmployee.user, newPassword);
          } else {
            alert('Employee not found');
            return false;
          }
        }}
      />
    </>
  );
};

export default MemberProfileUpdate;

interface CustomInfoIProps {
  heading: string;
  data: string;
  className?: string;
}

const CustomInfo = ({ heading, data, className }: CustomInfoIProps) => {
  return (
    <div className="flex">
      <p className="text-xl font-bold text-navy-blue tracking-[0.6px] leading-normal">{heading} ：</p>
      <p className={classNames('text-xl font-bold text-dark-grey tracking-[0.6px] leading-normal', className)}>
        {data}
      </p>
    </div>
  );
};

interface CustomSelectIProps {
  setValue: UseFormSetValue<UserProfileUpdateFormTypes>;
  selectedValue?: string;
  options: string[];
}

const CustomSelect = ({ setValue, selectedValue, options }: CustomSelectIProps) => {
  const dropDownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(selectedValue || '');
  const isOpenHandler = (val: boolean) => {
    setIsOpen((state) => {
      if (state == true && val == true) return false;
      return val;
    });
  };

  const isSelectedHandler = (val: string) => {
    setValue('group_name', val);
    setIsSelected(val);
  };

  useOutsideClick(dropDownRef, () => {
    if (isOpen) isOpenHandler(false);
  });

  return (
    <div
      className={classNames({
        'bg-white shadow-input-field relative rounded-2.5xl h-max z-50': isOpen
      })}
      ref={dropDownRef}
    >
      <div
        className={classNames(
          'bg-white relative z-20 min-[1700px]:h-11.5 min-[1700px]:w-[296px] min-[1500px]:w-[260px] min-[1300px]:w-[215px] h-[45px] w-[165px] min-[1500px]:px-6 min-[1300px]:px-4.5 px-3 py-2.5 outline-none  flex items-center cursor-pointer gap-2.5',
          {
            ' shadow-input-field rounded-full': !isOpen,
            'rounded-t-2.5xl': isOpen
          }
        )}
        onClick={() => isOpenHandler(true)}
      >
        <p className="min-[1700px]:text-lg min-[1500px]:text-base min-[1300px]:text-sm min-[1200px]:text-xs text-xms">
          {isSelected ? isSelected : '身份選擇'}
        </p>
        <img src="/v2/user-info-form/down-arrow.svg" className="" />
      </div>
      {isOpen && (
        <div className="absolute z-10 shadow-input-field rounded-b-2.5xl bg-white py-1 flex flex-col min-[1400px]:gap-4 gap-2 min-[1400px]:pb-3 pb-2 min-[1600px]:px-10 min-[1500px]:px-8.7 min-[1300px]:px-7 px-4.5 min-[1600px]:max-h-[132px] min-[1500px]:max-h-[122px] min-[1400px]:max-h-[110px] min-[1300px]:max-h-[93px] min-[1200px]:max-h-[77px] max-h-[70px] overflow-y-scroll overflow-x-hidden grayScrollNoBg w-full">
          {options.map((option, index) => (
            <label
              className={classNames(
                'bg-white hover:text-light-blue cursor-pointer min-[1600px]:text-lg min-[1500px]:text-base min-[1300px]:text-sm min-[1200px]:text-xs text-xms',
                {
                  'font-extrabold underline text-navy-blue leading-normal': isSelected === option
                }
              )}
              key={index}
              onClick={() => {
                isSelectedHandler(option);
                setIsOpen(false);
              }}
            >
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};
