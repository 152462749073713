import classNames from 'classnames';
import { FieldErrors, FieldValues, Path, useFormContext } from 'react-hook-form';

type CompanyTextInputFieldProps<T extends FieldValues> = {
  name: Path<T>;
  label: string;
  placeholder?: string;
  errors?: FieldErrors<FieldValues>;
  errorMessage?: string;
  className?: string;
  labelStyle?: string;
};

const CompanyTextInputField = <T extends FieldValues>(props: CompanyTextInputFieldProps<T>) => {
  const { name, placeholder, errors, label, errorMessage, className, labelStyle } = props;
  const { register } = useFormContext();

  return (
    <div className="flex gap-3 items-baseline">
      <label
        className={classNames(
          'text-black text-base not-italic font-normal leading-normal text-right min-w-[272px]',
          labelStyle
        )}
      >
        {label} :
      </label>
      <div>
        <input
          type="text"
          {...register(name)}
          className={classNames(
            'font-istok-web block text-field w-[368px] h-[33px] bg-white rounded-[45px] shadow-input-field-shadow text-[13px] font-bold not-italic leading-normal px-[14px]',
            className
          )}
          placeholder={placeholder}
        />
        {errors?.[name] && <p className="text-xs mt-1 ml-2 text-bright-red">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default CompanyTextInputField;
