import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import * as React from 'react';

import { RadioButtonGreyFilled, RadioButtonGreyOutline } from '@/svg';

interface IProps {
  desc: boolean | undefined;
  onSortChange: (sortType: 'price' | 'vintage', desc: boolean) => void;
  sortBy: 'price' | 'vintage' | undefined;
}

export default function SortFiltersModal({ desc, onSortChange, sortBy }: IProps) {
  const [openSortModal, setOpenSortModal] = React.useState(false);

  const handleClickAway = () => {
    setOpenSortModal(false);
  };

  const styles: SxProps = {
    position: 'absolute',
    top: 28,
    right: 0,
    zIndex: 1,
    p: 1,
    border: '2px solid #DFDFDF',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    color: 'black',
    width: '205px',
    borderRadius: '10px'
  };

  const textToShow = React.useCallback(() => {
    if (sortBy === 'price' && !desc) return 'Sort: Low to High';
    if (sortBy === 'price' && desc) return 'Sort: High to Low';
    if (sortBy === 'vintage' && desc) return 'Sort: Newest to Oldest';
    if (sortBy === 'vintage' && !desc) return 'Sort: Oldest to Newest';
    return '';
  }, [sortBy, desc]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="relative text-white flex gap-2.5 items-center">
        <span className="cursor-pointer text-[15px] tracking-[0.45px]" onClick={() => setOpenSortModal(true)}>
          {textToShow()}
        </span>
        {!openSortModal && <KeyboardArrowDownIcon />}
        {openSortModal && <KeyboardArrowUpIcon />}
        {openSortModal ? (
          <Box sx={styles}>
            <div className="p-1">
              <h6 className="text-dark-grey text-[15px] font-bold mb-2">Price</h6>
              <div
                onClick={() => onSortChange('price', false)}
                className="flex cursor-pointer justify-between items-center mb-1.5"
              >
                <span className="text-[#525252] text-[15px] font-normal">Low to High</span>
                {!desc && sortBy === 'price' ? (
                  <RadioButtonGreyFilled
                    onClick={() => {
                      onSortChange('price', false);
                    }}
                  />
                ) : (
                  <RadioButtonGreyOutline
                    onClick={() => {
                      onSortChange('price', false);
                    }}
                  />
                )}
                {/* <input type="radio" name="price" className="w-5 h-5" checked={!desc && sortBy === 'price'} /> */}
              </div>
              <div
                onClick={() => onSortChange('price', true)}
                className="flex cursor-pointer justify-between items-center mb-[21px]"
              >
                <span className="text-dark-grey text-[15px] font-normal">High to Low</span>
                {desc && sortBy === 'price' ? (
                  <RadioButtonGreyFilled
                    onClick={() => {
                      onSortChange('price', true);
                      setOpenSortModal(false);
                    }}
                  />
                ) : (
                  <RadioButtonGreyOutline
                    onClick={() => {
                      onSortChange('price', true);
                    }}
                  />
                )}
                {/* <input type="radio" name="price" className="w-5 h-5" checked={desc && sortBy === 'price'} /> */}
              </div>
              <h6 className="text-dark-grey text-[15px] font-bold mb-2">Vintages</h6>
              <div
                onClick={() => onSortChange('vintage', true)}
                className="flex justify-between items-center cursor-pointer"
              >
                <span className="text-dark-grey text-[15px] font-normal">Newest to Oldest</span>
                {sortBy === 'vintage' && desc ? (
                  <RadioButtonGreyFilled
                    onClick={() => {
                      onSortChange('vintage', true);
                      setOpenSortModal(false);
                    }}
                  />
                ) : (
                  <RadioButtonGreyOutline
                    onClick={() => {
                      onSortChange('vintage', true);
                    }}
                  />
                )}

                {/* <input type="radio" name="price" className="w-5 h-5" checked={sortBy === 'vintage' && desc} /> */}
              </div>
              <div
                onClick={() => onSortChange('vintage', false)}
                className="flex justify-between items-center mt-1.5 cursor-pointer"
              >
                <span className="text-dark-grey text-[15px] font-normal">Oldest to Newest</span>
                {sortBy === 'vintage' && !desc ? (
                  <RadioButtonGreyFilled
                    onClick={() => {
                      onSortChange('vintage', false);
                      setOpenSortModal(false);
                    }}
                  />
                ) : (
                  <RadioButtonGreyOutline
                    onClick={() => {
                      onSortChange('vintage', false);
                    }}
                  />
                )}
                {/* <input type="radio" name="price" className="w-5 h-5" checked={sortBy === 'vintage' && !desc} /> */}
              </div>
            </div>
          </Box>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}
