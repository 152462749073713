import { yupResolver } from '@hookform/resolvers/yup';
import { difference } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useCompanyStore } from '@/store/company';
import { CompanyRegistrationSteps } from '@/util/constants';

import CompanyTextInputField from './CompanyTextInputField';
import CustomButton from './CustomButton';
import FilesUploadField, { FileItem } from './FilesUploadField';

const maxFileSize = 2; // Max 2MB per file
interface IProps {
  nextStep: (val: number) => void;
}

const schema = yup.object({
  account_with_bank: yup.string().required('Account is required'),
  swift_code: yup.string().required('Representative is required'),
  bank_address: yup.string().required('Representative is required'),
  beneficiary_no: yup.string().required('Beneficiary account number is required'),
  beneficiary_name: yup.string().required('Beneficiary name is required'),
  beneficiary_address: yup.string().required('Beneficiary address is required'),
  account: yup
    .mixed<FileItem[]>()
    .default([])
    .test('fileSize', `檔案大小超過${maxFileSize}MB`, (items) => {
      if (items.length > 0) {
        for (const item of items) {
          if (item instanceof File && item.size > maxFileSize * 1024 * 1024) {
            return false;
          }
        }
      }
      return true;
    })
    .test('fileName', '重複上傳同一份檔案', (items) => {
      if (items.length > 0) {
        const fileNameList = items.map((item) => (item instanceof File ? item.name : '')).filter((name) => name !== '');
        // ref: https://stackoverflow.com/questions/19655975/check-if-an-array-contains-duplicate-values
        return fileNameList.length === new Set(fileNameList).size;
      }
      return true;
    })
    .test('filesLength', '請上傳存摺影本圖檔', (items) => items.length >= 1)
});

type FinancialFormTypes = yup.InferType<typeof schema>;

const FinancialInfoForm = ({ nextStep }: IProps) => {
  const formMethods = useForm<FinancialFormTypes>({ resolver: yupResolver(schema) });
  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = formMethods;

  // console.log('errors', errors);
  // console.log('values', formMethods.watch());

  const company = useCompanyStore((state) => state.company);
  const updateCompany = useCompanyStore((state) => state.updateCompany);
  const getCompany = useCompanyStore((state) => state.getCompany);

  useEffect(() => {
    (async () => {
      const companyData = await getCompany();
      if (!companyData) return;

      for (const [keyName] of Object.entries(getValues())) {
        const key = keyName as keyof FinancialFormTypes;
        const value = companyData[key];
        if (value) {
          // Put data from api into react hook form
          setValue(key, value);
        }
      }
    })();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(data)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formData.append(key, value as any);
      }

      formData.delete('account');
      if (difference(data.account, company.account || []).length > 0) {
        data.account.forEach((doc) => formData.append('account', doc));
      }

      const isSuccess = await updateCompany(formData);
      if (isSuccess) nextStep(CompanyRegistrationSteps.TERMS_CONFIRMATION);
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="px-10 xl:px-0 xl:max-w-[1360px] 2xl:max-w-[1550px] m-auto mt-[132px]">
          <div className="flex justify-between">
            <div className="flex flex-col gap-[22px]">
              <CompanyTextInputField
                name="account_with_bank"
                label="Account With Bank"
                placeholder=""
                errors={errors}
                errorMessage="Account With Bank is required"
                className="2xl:!w-[582px]"
                labelStyle="!min-w-[200px]"
              />
              <CompanyTextInputField
                name="swift_code"
                label="Swift Code"
                placeholder=""
                errors={errors}
                errorMessage="Swift Code is required"
                className="2xl:!w-[582px]"
                labelStyle="!min-w-[200px]"
              />
              <CompanyTextInputField
                name="bank_address"
                label="Bank Address"
                placeholder=""
                errors={errors}
                errorMessage="Bank Address is required"
                className="2xl:!w-[582px]"
                labelStyle="!min-w-[200px]"
              />
              <CompanyTextInputField
                name="beneficiary_no"
                label="Beneficiary’s A/C No."
                placeholder=""
                errors={errors}
                errorMessage="Beneficiary’s A/C No. is required"
                className="2xl:!w-[582px]"
                labelStyle="!min-w-[200px]"
              />
              <CompanyTextInputField
                name="beneficiary_name"
                label="Beneficiary’s Name"
                placeholder=""
                errors={errors}
                errorMessage="Beneficiary’s Name is required"
                className="2xl:!w-[582px]"
                labelStyle="!min-w-[200px]"
              />
              <CompanyTextInputField
                name="beneficiary_address"
                label="Beneficiary’s Address"
                placeholder=""
                errors={errors}
                errorMessage="Beneficiary’s Address is required"
                className="2xl:!w-[582px]"
                labelStyle="!min-w-[200px]"
              />
            </div>
            <div className="flex gap-2.7 2xl:min-w-[40%]">
              <h2 className="text-black text-base not-italic font-normal leading-normal text-right min-w-[100px]">
                存摺封面 :
              </h2>
              <FilesUploadField
                name="account"
                title="存摺封面影像檔上傳，限小於<span>2MB</span>的JPG、PNG檔案。"
                guideDownload={{
                  fileLinkPath: '/pdf/passbook.pdf',
                  text: '「了解存摺影本文件上傳規範」'
                }}
                fileSizeLimit={2}
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-7 right-5 flex gap-2">
          <CustomButton
            className="px-4.5 py-0.7 font-bold  rounded-md border border-navy-blue"
            variant="secondary"
            type="button"
            onClick={() => {
              nextStep(CompanyRegistrationSteps.REPRESENTATIVE_INFO_FORM);
            }}
          >
            修改 | 上一步
          </CustomButton>
          <CustomButton className="text-white bg-navy-blue px-4.5 py-0.7 font-bold rounded-md " type="submit">
            儲存 | 下一步
          </CustomButton>
        </div>
      </form>
    </FormProvider>
  );
};

export default FinancialInfoForm;
