import GoalDetailModal from './GoalDetailModal';

type GoalsTileProps = {
  img: string;
  title: string;
  description: string;
  content: string;
};

const GoalsTile = ({ title, description, img, content }: GoalsTileProps) => {
  return (
    <div className="h-[147px] w-full max-w-[715px] flex gap-5">
      <img src={img} alt="goal" className="w-[90px] h-[90px]" />
      <div className="flex flex-col justify-between flex-1">
        <div>
          <p className="text-white font-semibold text-2xl uppercase">{title}</p>
          <p className="text-white text-base text-[22px] text-left">{description}</p>
        </div>
        <GoalDetailModal icon={img} title={title} shortDesc={description} details={content} />
      </div>
    </div>
  );
};

export default GoalsTile;
