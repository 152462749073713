import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FieldErrors, Path, UseFormRegister } from 'react-hook-form';
import * as yup from 'yup';

import CustomButton from '@/components/CustomButton';
import Layout from '@/components/v2/Layout';
import { useCompanyStore } from '@/store/company';

import AccountPasswordChangeModal from './AccountPasswordChangeModal';

const schema = yup.object({
  last_name: yup.string().required('姓名為必填項'),
  position: yup.string().required('職位名稱為必填項'),
  email: yup.string().email('Enter valid address').required('電子郵件為必填項'),

  tel: yup
    .string()
    .required('例如：0x-000111 或 09xx-000111')
    .matches(/^09\d{8}$/, '例如：0x-000111 或 09xx-000111'),
  tel_extension: yup.string().required('#12345')
});

type AccountInfoValues = yup.InferType<typeof schema>;

const AccountInformation = () => {
  const [company, employee, getCompanyEmployee, updateCompanyEmployee] = useCompanyStore((state) => [
    state.company,
    state.employee,
    state.getCompanyEmployee,
    state.updateCompanyEmployee
  ]);
  const [passwordModal, setPasswordModal] = useState<boolean>(false);

  useEffect(() => {
    if (!employee) getCompanyEmployee();
  }, []);

  const [isFormActive, setIsFormActive] = useState(false);

  const textEllipsis = useCallback((text: string) => {
    if (!text) return '';
    return text.length < 22 ? text : `${text.slice(0, 10)}...${text.slice(-6)}`;
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<AccountInfoValues>({
    resolver: yupResolver(schema),
    values: {
      last_name: employee?.last_name || '',
      position: employee?.position || '',
      email: employee?.email || '',
      tel: employee?.tel || '',
      tel_extension: employee?.tel_extension || ''
    }
  });

  const accountInfoList = useMemo(() => {
    return [
      {
        key: '名稱',
        value: company.name || '-'
      },
      {
        key: '代表人',
        value: company.representative || '-'
      },
      {
        key: '統一編號',
        value: company.registration_number || '-'
      },
      {
        key: '電話',
        value: company.tel || '-'
      },
      {
        key: '財務聯絡人',
        value: company.finance_contact_person_name || '-'
      },
      {
        key: '財務電話',
        value: company.finance_contact_person_number || '-'
      },
      {
        key: '聯絡地址',
        value: company.address
          ? `${company.address.city}${company.address.district}${company.address.detailAddress}`
          : '-'
      }
    ];
  }, [company]);

  const onSubmit = handleSubmit(async (data) => {
    const isSuccess = await updateCompanyEmployee(data);
    if (isSuccess) {
      setIsFormActive(false);
    }
  });

  return (
    <Layout>
      <div className=" ml-14 mt-[80px] w-[90%] rounded-[10px] bg-transparent-blue opacity-[0.9] shadow-account-info-box min-h-[490px] 2xl:min-h-[560px] 2.5xl:min-h-[640px]">
        <div className="flex ">
          <div className="w-[50%]">
            <div className="account-information-clip-path rounded-tl-lg rounded-bl-lg bg-white min-h-[536px] xl:min-h-[560px] 2xl:min-h-[580px] 2.5xl:min-h-[640px]">
              <div className="pt-8 pl-8">
                <h2 className=" text-transparent-blue font-bold text-xl  border-b-[2.7px] border-solid w-[65%]">
                  <span className="">公司資訊</span>
                </h2>
                <div className="mt-6 2.5xl:mt-8 3xl:mt-12">
                  {accountInfoList.map(({ key, value }) => (
                    <div
                      key={key}
                      className="flex gap-1 text-navy-blue font-semibold 2xl:font-bold text-base xl:text-lg 2.5xl:text-xl mb-4 xl:mb-6 2.5xl:mb-8 first:pr-16 w-full"
                    >
                      <p className="whitespace-nowrap tracking-[0.2px]">{key}</p>:
                      <p className="pr-4 w-[65%] tracking-[0.6px]">{value}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="w-[50%]  flex flex-col items-center pt-8 pr-6 h-full">
            <div className=" pb-2 -ml-20 w-[117%] 2xl:-ml-28  2xl:w-[118%] ">
              <h2 className=" text-white text-left  font-bold text-xl border-b-[2.7px] border-solid w-full">
                個人資訊
              </h2>
              <div className="flex justify-end w-full mt-3">
                <button
                  className={classNames(
                    'font-istok-web relative inline-block underline underline-offset-4 font-bold text-pale-yellow cursor-pointer text-xl',
                    {
                      '!text-grey': isFormActive
                    }
                  )}
                  onClick={() => setIsFormActive(true)}
                >
                  變更資料
                </button>
              </div>
            </div>

            <div className="w-full h-74.7 xl:h-80 2xl:h-[350px] ">
              {isFormActive ? (
                <form onSubmit={onSubmit} className="flex justify-end mr-4 xl:block">
                  <div className="w-full flex flex-col gap-[11px] items-center">
                    <div className="flex gap-10 text-white w-full">
                      <p className="whitespace-nowrap text-xl font-bold tracking-[4px] leading-normal">帳號</p>
                      <p className="pr-4 w-[65%] tracking-[0.6px] leading-normal">{employee?.username || '-'}</p>
                    </div>
                    <div className="flex gap-10 text-white w-full">
                      <p className="whitespace-nowrap text-xl font-bold tracking-[4px] leading-normal">權限</p>
                      <p className="pr-4 w-[65%] tracking-[0.6px] leading-normal">{employee?.group_name || '-'}</p>
                    </div>
                    <CustomInput errors={errors} label="姓名" id="last_name" type="text" register={register} />
                    <CustomInput errors={errors} label="職稱" id="position" type="text" register={register} />
                    <CustomInput errors={errors} label="Email" id="email" type="text" register={register} />

                    <div className="flex w-full gap-2.5">
                      <div className=" flex w-[70%]">
                        <CustomInput
                          errors={errors}
                          label="電話"
                          id="tel"
                          type="text"
                          register={register}
                          defaultValue="#"
                          className="gap-6"
                        />
                      </div>
                      <div className="w-[30%]">
                        <CustomInput errors={errors} id="tel_extension" type="text" register={register} />
                      </div>
                    </div>

                    <div className="w-full justify-end items-start flex gap-3">
                      <CustomButton
                        type="submit"
                        variant="rounded"
                        className="bg-pale-yellow border border-pale-yellow !text-lg !text-transparent-blue self-end !py-[3px] !px-6 !font-bold"
                      >
                        儲存變更
                      </CustomButton>

                      <CustomButton
                        variant="rounded"
                        className="border-pale-yellow !text-lg !bg-[#b3b4b499] border !text-pale-yellow !py-[3px] !px-[42px] !font-bold"
                        onClick={() => setIsFormActive(false)}
                      >
                        取消
                      </CustomButton>
                    </div>
                  </div>
                </form>
              ) : (
                <div className="font-istok-web w-full">
                  <div className="flex flex-col gap-[25px] w-full justify-start text-white font-semibold 2xl:font-bold text-base xl:text-xl 2.5xl:text-2xl mb-4 xl:mb-6 2.5xl:mb-8 first:pr-16 ">
                    <p className="whitespace-nowrap  min-w-[75px] xl:min-w-[100px] flex gap-5">
                      <span className="text-xl font-bold tracking-[4px]">帳號</span>:
                      <span className="text-xl font-normal tracking-[0.6px]">{employee?.username || '-'}</span>
                    </p>
                    <p className="whitespace-nowrap min-w-[75px] xl:min-w-[100px] flex gap-5">
                      <span className="text-xl font-bold tracking-[4px]">權限</span>:
                      <span className="text-xl font-normal tracking-[0.6px]">{employee?.group_name || '-'}</span>
                    </p>
                    <p className="whitespace-nowrap min-w-[75px] xl:min-w-[100px] flex gap-5">
                      <span className="text-xl font-bold tracking-[4px]">姓名</span>:
                      <span className="text-xl font-normal tracking-[0.6px]">
                        {textEllipsis(employee?.last_name || '-')}
                      </span>
                    </p>
                    <p className="whitespace-nowrap min-w-[75px] xl:min-w-[100px] flex gap-5">
                      <span className="text-xl font-bold tracking-[4px]">職稱</span>:
                      <span className="text-xl font-normal tracking-[0.6px]">{employee?.position || ''}</span>
                    </p>
                    <p className="whitespace-nowrap min-w-[75px] xl:min-w-[100px] flex gap-5">
                      <span className="text-xl font-bold tracking-[0.6px]">Email</span>:
                      <span className="text-xl font-normal tracking-[0.6px]">
                        {textEllipsis(employee?.email || '-')}
                      </span>
                    </p>
                    <p className="whitespace-nowrap min-w-[75px] xl:min-w-[100px] flex gap-5">
                      <span className="text-xl font-bold tracking-[4px]">電話</span>:
                      <span className="text-xl font-normal tracking-[0.6px]">{employee?.tel || ''}</span>
                      <span className="text-xl font-normal tracking-[0.6px]">#{employee?.tel_extension || ''}</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="font-istok-web pb-2 w-full pt-8 2.5xl:pt-14 ">
              <button className=" text-white text-start font-bold text-xl border-b-[2.7px] border-solid w-full cursor-text">
                安全性密碼
              </button>
              <div className="flex justify-end w-full mt-3">
                <button
                  className="relative inline-block border-b-2 font-bold text-pale-yellow cursor-pointer text-xl"
                  onClick={() => setPasswordModal(true)}
                >
                  變更密碼
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AccountPasswordChangeModal
        open={passwordModal}
        setOpen={() => setPasswordModal(false)}
        isAdmin={false}
        onSubmit={async (newPassword, oldPassword) => {
          return await updateCompanyEmployee({
            old_password: oldPassword,
            password: newPassword
          });
        }}
      />
    </Layout>
  );
};

export default AccountInformation;

interface CustomInputIProps {
  id: Path<AccountInfoValues>;
  register: UseFormRegister<AccountInfoValues>;
  defaultValue?: string;
  type: string;
  placeholder?: string;
  label?: string;
  errors: FieldErrors<AccountInfoValues>;
  callback?: () => void;
  className?: string;
}

const CustomInput = ({ id, register, defaultValue, placeholder, label, errors, className }: CustomInputIProps) => {
  return (
    <div
      className={classNames('flex justify-between w-full gap-3 items-center', className, {
        'mb-0.5': errors && errors[id],
        '!gap-0 !block': id === 'tel_extension'
      })}
    >
      <p
        className={classNames('text-xl font-bold text-white text-start whitespace-nowrap w-[75px] tracking-[4px]', {
          '!tracking-[0.6px]': id === 'email'
        })}
      >
        {label}
      </p>
      <div className={classNames('flex flex-col relative w-full', { 'gap-0.5': errors && errors[id] })}>
        <input
          defaultValue={defaultValue}
          className="font-istok-web rounded-full px-4 py-1 xl:py-2 bg-white flex justify-between shadow-operator-signup-input active:shadow-account-input-field focus:shadow-account-input-field outline-none text-xl font-normal text-dark-grey tracking-[0.6px] leading-normal bg-transparent w-full"
          {...register(id)}
          placeholder={placeholder}
        />
        <div className="relative w-full">
          {errors && errors[id] && (
            <p className="min-[1500px]:text-xs min-[1200px]:text-xms text-xms text-bright-red ml-4 absolute">
              {errors[id]?.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
