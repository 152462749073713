import { Backdrop } from '@mui/material';
import { useState } from 'react';

import { ExtendCarbonCredit } from '@/libs/api';

type CreditRetiredModalProps = {
  issuedList: ExtendCarbonCredit['issued_credits_retired'];
};

const CreditRetiredModal = (props: CreditRetiredModalProps) => {
  const { issuedList } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="relative">
      <p className="text-pale-yellow text-lg font-bold text-right cursor-pointer" onClick={() => setShowModal(true)}>
        more
      </p>
      <Backdrop open={showModal} onClick={() => setShowModal(false)}>
        <div className="bg-white w-[346px] min-h-[200px] rounded-[10px] absolute right-[12%]">
          <img
            src="/images/products-page/ic_circle_close.svg"
            alt="close"
            width={32}
            height={32}
            className="absolute top-2.5 right-3 cursor-pointer"
            onClick={() => setShowModal(false)}
          />
          <div className="font-istok-web px-10 py-[42px]">
            <p className="text-[25px] font-bold leading-[29px] text-black mb-2">
              Percentage of <br /> Issued Credits Retired
            </p>
            {issuedList?.map((carbon) => {
              return (
                <p key={carbon.year} className="text-xl font-normal leading-[28.79px] tracking-[3%]">
                  {carbon.year} - {carbon.percentage}
                </p>
              );
            })}
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default CreditRetiredModal;
