import classNames from 'classnames';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomButton from '@/components/CustomButton';
import { BLUE, GREEN, YELLOW } from '@/util/constants';
interface IProps {
  title: string | null | undefined;
  userName: string;
  userEmail: string;
  id: number;
  img: string | undefined | null;
  status: number | undefined;
  statusText: string;
}

const EnterpriseCard = ({ title, userName, userEmail, img, status, statusText, id }: IProps) => {
  const randomColor = () => {
    const randomValue = Math.random();
    if (randomValue < 0.3) return YELLOW;
    if (randomValue < 0.9 && randomValue > 0.3) return GREEN;
    else return BLUE;
  };

  const navigate = useNavigate();

  // status 0尚未驗證 1驗證 2凍結
  const isActive = useMemo(() => status !== 2, [status]);

  return (
    <div
      className={classNames(
        'relative bg-white rounded-[10px] w-full 2xl:w-[305px] h-[316px] flex justify-center items-center group hover:border-4  transition-all cursor-pointer shadow-enterprise-card',
        {
          'border-dark-grey': !isActive
        }
      )}
      onClick={() => navigate(`/v2/profile-update/${id}`)}
    >
      <div
        className={classNames('w-full h-full absolute rounded-[10px]', {
          'bg-[#abababd1]': !isActive
        })}
      />
      {!isActive && (
        <CustomButton
          variant="rounded-full"
          className={classNames('absolute z-20 py-2.5 px-5 !bg-white !text-navy-blue font-sm font-bold')}
        >
          {statusText}
        </CustomButton>
      )}
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-navy-blue font-bold text-xl tracking-[-0.4px] leading-normal mb-[35px]">{title}</h1>
        <div
          className={classNames(
            'relative border-4 rounded-full bg-grey flex justify-center items-end h-[85px] w-[85px] border-light-green mb-5',
            {
              'border-pale-yellow': randomColor() === YELLOW,
              'border-light-green': randomColor() === GREEN,
              'border-navy-blue': randomColor() === BLUE
            }
          )}
        >
          <img src={img ?? '/images/enterprise-account/user-icon.svg'} alt="no Image" className="h-16" />
          <div
            className={classNames(
              'h-5 w-5 rounded-full border-4 border-white shadow-sm shadow-dark-grey absolute right-1',
              {
                'bg-soft-green': isActive,
                'bg-silverstone': !isActive
              }
            )}
          />
        </div>
        <div className="text-center">
          <h1 className="font-bold text-xl capitalize font-istok-web leading-normal tracking-[-0.4px]">{userName}</h1>
          <p className="font-normal text-dark-grey text-sm leading-normal tracking-[-0.28px]">{userEmail}</p>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseCard;
