import classNames from 'classnames';
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';

import { useWishListStore } from '@/store/wishList';
import { CarbonTag } from '@/type';

// import { formatNumberByComma } from '@/util/helper';
import CustomButton from '../CustomButton';
import CustomRating from '../Rating';

interface IProps {
  id: number;
  tag?: string | CarbonTag;
  name: string;
  image: string;
  rating?: string;
  standard?: string;
  type?: string;
  coBenefit: number;
  vintage: string;
  minPrice: string;
  maxPrice: string;
  location: string;
}

const Tile = ({
  id,
  tag,
  name,
  rating,
  image,
  standard,
  type,
  coBenefit,
  vintage,
  // minPrice,
  // maxPrice,
  location
}: IProps) => {
  const addToWhishList = useWishListStore((store) => store.addToWhishList);
  const deleteWishList = useWishListStore((store) => store.deleteWishList);
  const wishList = useWishListStore((store) => store.wishList);

  const wishItem = wishList.find((wishItem) => wishItem.carbon_credit === id);

  let linkToRedirect = `/new-product-details/${id}?type=green`;

  if (tag === CarbonTag.Green) linkToRedirect = `/new-product-details/${id}?type=green`;
  if (tag === CarbonTag.Yellow) linkToRedirect = `/new-product-details/${id}?type=yellow`;
  if (tag === CarbonTag.Blue) linkToRedirect = `/new-product-details/${id}?type=blue`;

  return (
    <div
      className={classNames('bg-card-bg py-[20px] px-[24px] rounded-2xl flex items-center gap-6', {
        // if product status is sold out then add bg-[#ffffff4d]
        '!bg-[#ffffff4d]': false
      })}
    >
      <div className="flex gap-5">
        {/* first col */}
        <div className="w-[300px] h-[190px]">
          <img
            src={image}
            alt="sacurn"
            className={classNames('h-full w-full object-cover border-[5px] rounded-[14px]', {
              'border-light-green': tag === CarbonTag.Green,
              'border-yellow': tag === CarbonTag.Yellow,
              'border-navy-blue': tag === CarbonTag.Blue
            })}
          />
        </div>
      </div>
      <div className="w-full">
        <h3 className="text-lg font-bold text-black leading-[20px]">{name}</h3>
        <div className="flex justify-between items-end">
          {/* second col */}
          <div>
            <div className="flex gap-[5px] items-center">
              <img src="/images/products/location.svg" alt="location" className="inline-block w-[8px] h-[10.75px]" />
              <p className="text-[15px] text-dark-grey leading-normal my-0.5">{location}</p>
            </div>
            {rating && (
              <>
                <div className="inline-flex justify-center items-center bg-white rounded-full px-3 text-blue text-sm font-bold mb-0 h-5 leading-normal">
                  Rating {rating}
                </div>
                <br />
                <div className="inline-flex items-center bg-white gap-1.5 bg-red-200 rounded-full px-4 text-blue text-sm font-bold h-5">
                  Co-Benefit
                  <CustomRating count={coBenefit} />
                </div>
              </>
            )}

            <div className="mt-6">
              {standard && (
                <div className="flex gap-[41px] items-center leading-normal">
                  <p className="font-[13px] text-dark-grey min-w-[66px]">Standard</p>
                  <p className="font-[13px] text-black flex-1">{standard}</p>
                </div>
              )}
              {type && (
                <div className="flex gap-[41px] items-center leading-normal">
                  <p className="font-[13px] text-dark-grey min-w-[66px]">Type</p>
                  <p className="font-[13px] text-black flex-1">{type}</p>
                </div>
              )}
              <div className="flex gap-[41px] items-center leading-normal">
                <p className="font-[13px] text-dark-grey min-w-[66px]">Vintage</p>
                <p className="font-[13px] text-black flex-1">{dateFormat(vintage, 'yyyy')}</p>
              </div>
            </div>
          </div>
          {/* third col */}
          <div className="flex flex-1 flex-col justify-between items-end h-full gap-5">
            <div>
              {/* <div className="flex items-center gap-1 whitespace-nowrap">
                        <p className="text-sm text-black">TWD</p>
                        <p className="text-xl font-bold text-black">
                          {formatNumberByComma(minPrice || '0')} ~ {formatNumberByComma(maxPrice || '0')}
                        </p>
                        <p className="text-[10px] text-black">/Tonne</p>
                      </div> */}
              <div className="h-full flex flex-col items-end gap-1 pt-2 pr-1">
                <Link to={`/product-carbon/${id}`}>
                  <img
                    src="/images/products/green/dollar2.svg"
                    alt="sacurn"
                    className="w-11 h-11 object-contain relative left-2"
                    width={44}
                    height={44}
                  />
                </Link>
                {wishItem ? (
                  <button onClick={() => deleteWishList(wishItem.id)}>
                    <img
                      src="/images/wishlist/favicon.svg"
                      alt="sacurn"
                      className="w-[50px] h-[50px] object-contain relative left-3.5"
                    />
                  </button>
                ) : (
                  <button onClick={() => addToWhishList(id)}>
                    <img
                      src="/images/wishlist/unfavicon.svg"
                      alt="sacurn"
                      className="w-[50px] h-[50px] object-contain relative left-3.5"
                    />
                  </button>
                )}
              </div>
            </div>
            <Link to={linkToRedirect} className="w-full max-w-[152px]">
              <CustomButton variant="primary" className="w-full flex items-center gap-2 justify-center">
                details
                <img src="/images/products/green/arrow.svg" alt="arrow-right" className="ml-2" />
              </CustomButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tile;
