import classNames from 'classnames';

interface Props {
  value: string;
  setValue: (text: string, isSearchButtonTrigger: boolean) => void;
  className?: string;
  isSearchIcon: boolean;
  setIsSearchIcon: (value: boolean) => void;
  placeholder: string;
}

const Searchbar = ({ value, setValue, className, isSearchIcon, setIsSearchIcon, placeholder }: Props) => {
  return (
    <div
      className={classNames(
        'bg-white-smoke-2 w-[290px] h-[34px] rounded-[10px] flex justify-between items-center',
        className
      )}
    >
      <input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value, false)}
        placeholder={placeholder}
        className="bg-transparent pl-4 h-full outline-none text-white text-[15px] font-normal tracking-[1.05px] leading-normal placeholder-white w-[85%]"
      />
      <div className="flex justify-between w-[44px]">
        <div className="bg-white w-[1.5px] rounded-md" />
        {isSearchIcon ? (
          <span
            className="pr-[12px] cursor-pointer"
            onClick={() => {
              setValue(value, true);
              setIsSearchIcon(false);
            }}
          >
            <img src="/images/products/search-icon.svg" alt="search" className="w-5 h-5 object-contain" />
          </span>
        ) : (
          <span
            className="mr-[9px] cursor-pointer"
            onClick={() => {
              setIsSearchIcon(true);
              setValue('', false);
            }}
          >
            <img src="/images/products/cross-red-icon.svg" alt="search" className="w-5 h-5 object-contain" />
          </span>
        )}
      </div>
    </div>
  );
};

export default Searchbar;
