import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { useCarbonCreditStore } from '@/store/carbonCredit';
import { useWishListStore } from '@/store/wishList';
import { formatNumberByComma } from '@/util/helper';

import Navbar from '../Navbar';
import CreditRetiredModal from './CreditRetiredModal';
import GoalsTile from './GoalsTile';
import ImgSlider from './ImgSlider';
import NavigationTabs from './NavigationTabs';

const NewProductDetails = () => {
  const [idInWishlist, setIdInWishlist] = useState<number>(0);
  const param = useParams();
  const carbonCredit = useCarbonCreditStore((state) => state.carbonCredit);
  const getCarbonCredit = useCarbonCreditStore((state) => state.getCarbonCredit);
  const addToWhishList = useWishListStore((store) => store.addToWhishList);
  const deleteWishList = useWishListStore((store) => store.deleteWishList);
  const wishList = useWishListStore((store) => store.wishList);
  const getWishList = useWishListStore((store) => store.getWishList);

  console.log(carbonCredit);

  useEffect(() => {
    const idInWishlist = wishList.find((item) => param?.id && item.carbon_credit === +param?.id)?.id || 0;
    setIdInWishlist(idInWishlist);
  }, [wishList]);

  useEffect(() => {
    if (!param.id) return;
    getCarbonCredit(Number(param.id));
    getWishList();
  }, []);

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const type = params.get('type');

  return (
    <div
      className={classNames('bg-no-repeat bg-cover bg-center h-screen min-h-[700px]', {
        "bg-[url('../public/images/products/green/bg-green.png')]": type === 'green' || !type,
        "bg-[url('../public/images/products/yellow/bg-yellow.png')]": type === 'yellow',
        "bg-[url('../public/images/products/blue/bg-blue.png')]": type === 'blue'
      })}
    >
      <div className="relative py-4">
        <Navbar />
      </div>
      <div className=" overflow-y-scroll overflow-x-hidden yellowScroll 2xl:h-[90vh] h-[87vh] mr-[17px]">
        <img
          src="/scroller-icon.svg"
          alt="sacurn"
          className="w-[41.349px] h-[41.349px] absolute bottom-10 right-12 z-10"
          onClick={() => {
            if (
              document.getElementById('section-2') ||
              document.getElementById('section-3') ||
              document.getElementById('section-4')
            ) {
              document.getElementById('section-1')?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        />
        <div className="pl-5 pr-4.2" id="section-1">
          <div className="flex items-center 2xl:gap-10 gap-5">
            <ImgSlider images={carbonCredit.image_list || []} location={carbonCredit.location || ''} />
            <div className="font-istok-web flex-1">
              <h2 className="2xl:text-[30px] text-[22px] font-normal text-white leading-normal font-istok-web ml-2.5 mr-1.9">
                {carbonCredit.name}
              </h2>
              <div className="flex border border-white h-[145px] mt-2.5 mr-[23px]">
                <div className="border-r border-white h-full min-[1875px]:w-[20%] 3xl:w-[254px]">
                  <div className="flex justify-center px-3 min-w-[175px]">
                    <div className="min-[1875px]:w-[166px]">
                      <p className="min-[1775px]:text-lg 2xl:text:base font-bold leading-normal text-white text-center mt-[13px]">
                        Ratings Breakdown
                      </p>
                      <div className="flex gap-2 justify-between items-center mt-1">
                        <p className="text-white text-sm font-bold leading-normal tracking-[0.98px]">Carbon Rating</p>
                        <p className="text-pale-yellow text-[30px] font-bold leading-normal tracking-[2.153px]">
                          {carbonCredit.carbon_rating}
                        </p>
                      </div>
                      <div className="flex gap-2 justify-between items-center">
                        <p className="text-white text-sm font-bold leading-normal tracking-[0.98px]">Co-benefit</p>
                        <p className="text-pale-yellow text-[30px] font-bold leading-normal tracking-[2.153px] relative left-1">
                          {carbonCredit.co_benefit}
                          <span className="text-white text-[13px] font-bold leading-normal tracking-[0.904px]">/5</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-x border-white h-full w-[26%] 3xl:w-[339px] min-w-[250px]">
                  <div>
                    <p className="min-[1775px]:text-lg 2xl:text-base font-bold leading-normal text-white mt-[13px] pl-6">
                      Certification From
                    </p>
                    <div>
                      <img src={carbonCredit.certification_from_image} alt="carbon credit icon" />
                    </div>
                  </div>
                </div>
                <div className="border-x border-white h-full min-[1875px]:w-[31%] min-[1700px]:min-w-[445px] px-2.5 pt-3.2">
                  <p className="min-[1775px]:text-lg 2xl:text-base font-bold font-istok-web text-white ml-3.5">Price</p>

                  <p className="font-bold text-pale-yellow font-istok-web ml-3.5">
                    {carbonCredit.min_price && carbonCredit.max_price ? (
                      <>
                        <span className="min-[1775px]:text-[19.2px] 2xl:text-base text-sm">USD</span>
                        <span className="min-[1770px]:text-[43px] 2xl:text-[36px] text-[28px]">
                          {formatNumberByComma(carbonCredit.min_price)}~${formatNumberByComma(carbonCredit.max_price)}
                        </span>
                        <span className="text-[12.3px]">/Tonne</span>
                      </>
                    ) : (
                      <span className="min-[1770px]:text-[43px] 2xl:text-[36px] text-[28px]">SOLD OUT</span>
                    )}
                  </p>
                  <p className="text-sm font-bold text-white tracking-[0.98px] ml-3.5">
                    Ranges from vintage {carbonCredit.vintage}
                  </p>
                </div>
                <div className="border-l border-white h-full pl-3.2 pt-3.2 flex min-w-[230px]">
                  <div className="flex flex-col gap-1.2 items-center relative">
                    <p className="min-[1775px]:text-lg 2xl:text-base font-bold font-istok-web text-white">
                      {idInWishlist > 0 ? 'Delete Wishlist' : 'Add to Wish List'}
                    </p>

                    <img
                      src={idInWishlist > 0 ? '/images/products/favor_add.svg' : '/images/products/favor_remove.svg'}
                      alt="favourite-icon"
                      className="cursor-pointer"
                      width={78}
                      height={69}
                      onClick={() =>
                        idInWishlist > 0 ? deleteWishList(idInWishlist) : addToWhishList(carbonCredit?.id)
                      }
                    />
                    <div className="absolute w-0.5 h-[26px] bg-white right-[-3px] top-12"></div>
                  </div>

                  <div className="flex flex-col gap-1.2 items-center ml-3">
                    <p className="min-[1775px]:text-lg 2xl:text-base font-bold font-istok-web text-white">Prices</p>
                    <Link to={`/product-carbon/${carbonCredit?.id}`}>
                      <img
                        src="/images/products/dollar.svg"
                        alt="dollar-icon"
                        className="cursor-pointer"
                        width={77}
                        height={77}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="xl:w-[217px] w-[180px] h-9.5 border border-[#A0ADB7] rounded-[10px] flex items-center justify-center gap-2.5 ml-auto shadow-[0px 1.788px 8.342px 0px rgba(0, 0, 0, 0.45), 0px 0px 10.726px 0px rgba(0, 84, 135, 0.20)] mr-4.2 my-2.5 hover:bg-[#dfdfdf41] cursor-pointer active:translate-y-0.5"
          onClick={() => navigate('/')}
        >
          <p className="font-normal text-base text-white">Back</p>
          <img src="/images/products-page/back-icon.svg" alt="sacurn" width={23} height={16} />
        </div>
        <div className="pl-5 pr-4.2">
          <div className="border bg-[#1b1b1b4d] border-pale-yellow pt-[33px] px-[30px]">
            <NavigationTabs active={1} className="mb-[21px]" />
            <div className="gap-[72px] flex items-center justify-center">
              <div className="h-[1px] bg-white w-[32%]"></div>
              <h3 className="text-[28px] leading-10 text-white">Information</h3>
              <div className="h-[1.3px] bg-white w-[32%]"></div>
            </div>

            <div className="text-white indent-9 text-justify font-istok-web text-xl font-normal leading-7 tracking-[1.4px] mb-20">
              <p>{carbonCredit.information}</p>
            </div>

            <div className="flex gap-[33px] mb-[58px]">
              {carbonCredit.impacts?.map((impact) => {
                return (
                  <div key={impact.id} className="border border-white flex-1 px-8 py-2.7 flex gap-[22px]">
                    <div className="flex items-center">
                      <img src={impact.icon} width={55} height={40} className="w-[55px] h-10" alt={impact.name} />
                    </div>
                    <div>
                      <div className="flex gap-4 items-center">
                        <h5 className="text-2xl font-bold text-white font-istok-web">{impact.name}</h5>
                      </div>
                      <p className="text-white text-lg font-normal">{impact.desc}</p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="py-5 px-[55.55px]">
              <div className="flex flex-col gap-5">
                <div className="flex justify-between">
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Project Code</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.project_code}</p>
                  </div>
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Location</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.location}</p>
                  </div>
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Type</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.type}</p>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Available Vintages</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.available_vintages}</p>
                  </div>
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Hectares</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">
                      {formatNumberByComma(carbonCredit.hectares || '')}
                    </p>
                  </div>
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Developer</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">{carbonCredit.developer}</p>
                  </div>
                </div>
              </div>

              <hr className="h-[3.33px] bg-light-grey my-[30px] pt-[2.5px]" />

              <div className="flex flex-col gap-5">
                <div className="flex justify-between">
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Standards & Methodology</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">
                      {carbonCredit.standard_methodology}
                    </p>
                  </div>
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Project Validator</p>
                    {carbonCredit.project_validator?.map((validator) => {
                      return (
                        <a key={validator.name} href={validator.url || ''} target="_blank" rel="noopener noreferrer">
                          <div className="flex gap-2.7">
                            <p className="text-lg font-bold tracking-[0.54px] text-white">{validator.desc}</p>
                            <img src="/images/products/external-link.svg" alt="info" />
                          </div>
                        </a>
                      );
                    })}
                  </div>
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">CCS Validator</p>
                    {carbonCredit.ccs_validator?.map((validator) => {
                      return (
                        <a key={validator.name} href={validator.url || ''} target="_blank" rel="noopener noreferrer">
                          <div className="flex gap-2.7">
                            <p className="text-lg font-bold tracking-[0.54px] text-white">{validator.desc}</p>
                            <img src="/images/products/external-link.svg" alt="info" />
                          </div>
                        </a>
                      );
                    })}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">
                      Additional Certifications
                    </p>
                    {carbonCredit.additional_certifications?.map((certification) => {
                      return (
                        <p key={certification.name} className="text-lg font-bold tracking-[0.54px] text-white">
                          {certification.name}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>

              <hr className="h-[3.33px] bg-light-grey my-[30px] pt-[2.5px]" />

              <div className="flex flex-col gap-11">
                <div className="flex justify-between">
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Volume Issued</p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">
                      {formatNumberByComma(carbonCredit.volume_issued || '')} Tonnes
                    </p>
                  </div>
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">
                      Avg. Annual Emission Reduction
                    </p>
                    <p className="text-lg font-bold tracking-[0.54px] text-white">
                      {formatNumberByComma(carbonCredit.avg_annual_emission_reduction || '')}
                    </p>
                  </div>
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">
                      Percentage of Issued credits Retired
                    </p>
                    {carbonCredit.issued_credits_retired?.map((carbon) => {
                      return (
                        <p key={carbon.year} className="text-lg font-bold tracking-[0.54px] text-white font-istok-web">
                          {carbon.year} - {carbon.percentage}
                        </p>
                      );
                    })}
                    <CreditRetiredModal issuedList={carbonCredit.issued_credits_retired} />
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="max-w-[440px] flex-1">
                    <p className="text-[25px] leading-10 tracking-[0.75px] text-pale-yellow">Project Documents </p>
                    <div className="flex gap-[33.33px]">
                      {carbonCredit.project_documents?.map((doc) => {
                        return (
                          <a key={doc.url} href={doc.url || ''} target="_blank" rel="noopener noreferrer">
                            <div className="flex gap-2.7">
                              <p className="text-lg font-bold tracking-[0.54px] text-white">{doc.name}</p>
                              <img src="/images/products/external-link.svg" alt="info" />
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <hr className="h-[3.33px] bg-light-grey mt-20 mb-[88px] pt-[2.5px] mx-auto w-[90%]" id="section-2" />
            </div>

            <NavigationTabs active={2} className="mb-8" />
            {/*  */}
            <div className="flex flex-col gap-[30px] text-white">
              <div className="flex flex-col gap-4">
                <p className="text-xl font-bold tracking-[0.6px]">Carbon Risk Rating</p>
                <div className="flex gap-5 items-center">
                  <div className="w-[198px] h-[92px] rounded-[5px] bg-[#c6c6c640] flex flex-col items-center">
                    <p className="text-[35px] font-bold tracking-[2.5px]">{carbonCredit.carbon_rating}</p>
                    <p className="text-[21px] font-bold tracking-[1.5px]">Carbon Rating</p>
                  </div>
                  <p className="text-[28px] tracking-[0.8px]">{carbonCredit.rating_desc}</p>
                </div>
              </div>
              <div className="px-2.5 py-5 bg-[#adadad40]">
                <p className="text-xl leading-7 tracking-[0.6px] text-justify indent-8">{carbonCredit.rating_text}</p>
              </div>
              <div className="pl-2.2 border-l-[3px] border-white">
                <p className="text-xl leading-7 tracking-[0.6px] text-justify indent-9">{carbonCredit.forest_text}</p>
              </div>
              <div className="w-full h-[700px]">
                <img src={carbonCredit.map} alt="impact" className="w-full h-full object-cover" />
              </div>
            </div>

            <hr className="h-[3.33px] bg-light-grey mt-20 my-[88px] pt-[2.5px] mx-auto w-[84%]" id="section-3" />
            <NavigationTabs active={3} className="mb-8" />
            {/*  */}
            <div className="flex gap-8">
              <div className="flex-1">
                <img src={carbonCredit.impact_image || ''} alt="impact" className="w-full h-full object-cover" />
              </div>
              <div className="flex flex-col gap-[33px] w-[35.3%]">
                {carbonCredit.impacts?.map((impact) => {
                  return (
                    <div
                      key={impact.id}
                      className="w-full h-[200px] border border-white px-[9.6%] py-[11.11px] flex flex-col justify-center"
                    >
                      <div className="flex gap-[22.22px]">
                        <div className="flex items-center min-w-[55px]">
                          <img src={impact.icon} alt="tree" width={55} height={55} />
                        </div>
                        <div className="text-white font-istok-web flex flex-col gap-[11.11px]">
                          <h3 className="text-[25px] font-bold font-istok-web">{impact.name}</h3>
                          <p className="text-[18px] font-medium">{impact.desc}</p>
                          <p className="text-sm leading-4 text-light">{impact.content}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <h3 className="text-[25px] font-bold tracking-[0.75px] text-white text-center px-3 border-l-4 border-r-4 border-white w-fit mx-auto my-[33px]">
              This project contributes to {carbonCredit.sdg_list?.length || 0} Sustainable Development Goals
            </h3>
            {/* create grid with twoo cards in each row */}
            <div className="grid grid-cols-2 mb-7 gap-y-5 gap-x-[12.4%] max-w-[1635px] mx-auto" id="section-4">
              {carbonCredit.sdg_list?.map((sdgItem) => (
                <GoalsTile
                  key={sdgItem.id}
                  img={sdgItem.icon}
                  title={sdgItem.name}
                  description={sdgItem.desc}
                  content={sdgItem.content}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProductDetails;
