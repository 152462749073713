import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import CustomButton from '@/components/CustomButton';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (newPassword: string, oldPassword?: string) => Promise<boolean>;
  isAdmin: boolean;
}

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    // padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    // padding: theme.spacing(1)
  }
}));

const AccountPasswordChangeModal = ({ open, setOpen, onSubmit, isAdmin }: IProps) => {
  const Schema = yup.object({
    // if isAdmin is true, then it will show the old password field required
    oldPassword: isAdmin ? yup.string() : yup.string().required('請輸入目前的密碼'),
    newPassword: yup.string().required('請輸入新密碼'),
    confirmNewPassword: yup
      .string()
      .required('請再次輸入新密碼')
      .oneOf([yup.ref('newPassword')], '輸入的2次密碼不相同')
  });

  type FormType = yup.InferType<typeof Schema>;

  const ACCOUNT_PASSWORD_CHANGE_DATA: { label: string; name: keyof FormType }[] = [
    {
      label: '目前的密碼 ：',
      name: 'oldPassword'
    },
    {
      label: '設定新密碼 ：',
      name: 'newPassword'
    },
    {
      label: '確認您設定的新密碼 ：',
      name: 'confirmNewPassword'
    }
  ];

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const { register, handleSubmit, formState, reset } = useForm<FormType>({
    resolver: yupResolver(Schema),
    values: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  });

  console.log('error', formState.errors);

  const onFormSubmit = handleSubmit(async (values) => {
    const isSuccess = await onSubmit(values.confirmNewPassword, values.oldPassword);

    if (isSuccess) handleClose();
  });

  const inputFields = isAdmin ? ACCOUNT_PASSWORD_CHANGE_DATA.slice(1) : ACCOUNT_PASSWORD_CHANGE_DATA;

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="sm"
        sx={{
          '& .MuiPaper-rounded': {
            borderRadius: '16px',
            width: '460px',
            minHeight: isAdmin ? '564px' : '600px',
            paddingX: '40px',
            paddingY: '30px'
          }
        }}
      >
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <form onSubmit={onFormSubmit} className="flex flex-col flex-1">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 1
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <DialogTitle
                  sx={{
                    m: 0,
                    color: '#000000',
                    fontWeight: 700,
                    fontSize: '25px',
                    textAlign: 'center',
                    letterSpacing: '2.5px',
                    lineHeight: 'normal',
                    marginBottom: '41px',
                    padding: '0px'
                  }}
                  id="customized-dialog-title"
                >
                  變更密碼
                </DialogTitle>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
                  {inputFields.map((item, index) => {
                    return (
                      <div key={index} className="flex flex-col gap-[14px]">
                        <p className="font-semibold text-dark-grey text-[17px] tracking-[0.51px] leading-normal text-justify">
                          {item.label}
                        </p>
                        <div>
                          <input
                            type="password"
                            className="w-full px-2.5 py-3 outline-none rounded-md border-[1px] border-solid border-dark-grey shadow-account-password-input-field"
                            {...register(item.name)}
                          />
                          {formState.errors[item.name] && (
                            <p className="text-bright-red">{formState.errors[item.name]?.message}</p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '29px'
                }}
              >
                <CustomButton onClick={handleClose} variant="outline-sm" type="button">
                  取消
                </CustomButton>
                <CustomButton
                  variant="primary"
                  className="!text-lg !font-bold !w-[122px] !h-[35px] !rounded-[10px]"
                  type="submit"
                >
                  確認
                </CustomButton>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default AccountPasswordChangeModal;
