import { Backdrop } from '@mui/material';
import { useState } from 'react';

interface GoalDetailModalIProps {
  icon: string;
  title: string;
  shortDesc: string;
  details: string;
}

const GoalDetailModal = ({ icon, title, shortDesc, details }: GoalDetailModalIProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <p className="ml-auto text-pale-yellow text-[15px] font-bold" onClick={() => setShowModal(true)}>
        View more
      </p>

      <Backdrop open={showModal} onClick={() => setShowModal(false)}>
        <div className="bg-white w-[942px] rounded-[10px] flex flex-col items-center px-[67px] py-[41px] relative">
          <img
            src="/images/products-page/ic_circle_close.svg"
            alt="close"
            width={32}
            height={32}
            className="absolute top-2.5 right-3 cursor-pointer"
            onClick={() => setShowModal(false)}
          />
          <div className="flex gap-5">
            <img
              width={90}
              height={90}
              src={icon}
              alt="goal"
              className="min-w-[90px] min-h-[90px] max-w-[90px] max-h-[90px]"
            />
            <div>
              <h3 className="text-dark-grey font-semibold uppercase mb-1.5 text-[25px]">{title}</h3>
              <p className="text-dark-grey text-[22px]">{shortDesc}</p>
              <div className="w-[114px] h-[3px] bg-[#888] my-[9px]"></div>
              <div className="text-[20px] leading-7 text-dark-grey">
                {details.split('\n').map((detail) => {
                  return (
                    <p key={detail} className="indent-8">
                      {detail}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Backdrop>
    </>
  );
};

export default GoalDetailModal;
